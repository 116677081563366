import React from 'react';
import PropTypes from 'prop-types';
import pure from 'recompose/pure';

export const VideoField = ({ elStyle = {}, record, source, title }) => {
    const src = record[source];
    return (
        <div>
            <video width="480" height="640" style={{margin:'15px'}} controls autoPlay>
                <source src={src} type="video/mp4" />
            </video>
            <h3><a href={src}>Download video</a></h3>
        </div>
    );
};

VideoField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};


const PureVideoField = pure(VideoField);

PureVideoField.defaultProps = {
    addLabel: true,
};

export default PureVideoField;
