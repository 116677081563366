
const messages = {
    en: {
        resources: {
            boxes: {
                name: 'Box |||| Boxes',
                fields: {
                    event_id: 'Event',
                    date_last_login: 'Last connection',
                    white_balance_temperature: 'White Balance Temperature (Kelvin)',
                    white_balance_tint: 'White Balance Tint',
                    exposure_bias: 'Exposure correction (EV)',
                },
            },
            events: {
                name: 'Event |||| Events',
                fields: {
                    date_created: 'Creation date',
                    snap_count: 'Snap count',
                    name: 'Name',
                    mask_id: "Mask",
                    sender: "Sender",
                    subject: "Subject",
                    message: "Message",
                    hashtag: "Hashtag",
                    subtitle: "Subtitle",
                    url: "Gallery URL",
                    facebook_page: "Page Facebook",
                }
            },
            masks: {
                name: 'Mask |||| Masks',
                fields: {
                    date_created: 'Creation date',
                    name: "Name",
                    is_animated: "Animated?",
                    maskfiles: "Provide 1 PNG for a static mask or 11 PNG for an animated mask",
                }
            },
            musics: {
                name: 'Music |||| Musics',
                fields: {
                    date_created: 'Added date',
                    name: "Name",
                    length: "Length",
                    time_to_skip: "Time to skip",
                    musicfile: "Provide MP3 file",
                }
            },
            videos: {
                name: 'Video |||| Videos',
                fields: {
                    date_created: 'Creation date',
                    name: "Name",
                    file: "Video",
                    event_id: "Event",
                    snap_count: "Snap count",
                    snap_selection: "Selection",
                    snap_date_min: "Start date",
                    snap_time_min: "Start time",
                    snap_date_max: "End date",
                    snap_time_max: "End time",
                    music_id: "Music",

                }
            },
            report: {
                name: 'Report |||| Reports',
                fields: {
                    date_created: 'Creation date',
                    event_id: "Event",
                    begin_date: "Begin date",
                    end_date: "End date",
                    name: "Owner",
                    url: "Report URL",
                    theme: "Page theme",
                }
            },
        },
    },
    fr: {
        resources: {
            boxes: {
                name: 'Borne |||| Bornes',
                fields: {
                    event_id: 'Événement',
                    date_last_login: 'Dernière connexion',
                    white_balance_temperature: 'Balance des blancs (Kelvin)',
                    white_balance_tint: 'Tinte (vert/magenta)',
                    exposure_bias: "Correction d'exposition (EV)",
                },
            },
            events: {
                name: 'Événement |||| Événements',
                fields: {
                    date_created: 'Date de création',
                    snap_count: 'Nombre de snaps',
                    name: 'Nom',
                    mask_id: "Masque",
                    sender: "Expéditeur",
                    subject: "Sujet",
                    message: "Message",
                    hashtag: "Hashtag",
                    subtitle: "Sous-titre",
                    url: "Adresse web de la gallerie",
                    facebook_page: "Page Facebook",
                }
            },
            masks: {
                name: 'Masque |||| Masques',
                fields: {
                    date_created: 'Date de création',
                    name: "Nom",
                    is_animated: "Animé ?",
                    maskfiles: "Veuillez founir 1 fichier PNG (masque statique) ou 11 fichier PNG (masque animé)",
                }
            },
            musics: {
                name: 'Musique |||| Musiques',
                fields: {
                    date_created: "Date d'ajout",
                    name: "Nom",
                    length: "Durée",
                    time_to_skip: "Démarre à",
                    musicfile: "Veuillez fournir un fichier MP3",
                }
            },
            videos: {
                name: 'Vidéo |||| Vidéos',
                fields: {
                    date_created: 'Date de création',
                    name: "Nom",
                    file: "Vidéo",
                    event_id: "Événement",
                    snap_count: "Nombre de snaps",
                    snap_selection: "Sélection",
                    snap_date_min: "Date de début",
                    snap_time_min: "Heure de début",
                    snap_date_max: "Date de fin",
                    snap_time_max: "Heure de fin",
                    music_id: "Musique",
                }
            },
            report: {
                name: 'Rapport |||| Rapports',
                fields: {
                    date_created: 'Date de création',
                    event_id: "Événement",
                    begin_date: "Date de début",
                    end_date: "Date de fin",
                    name: "Propriétaire",
                    url: "L'url du rapport",
                    theme: "Thème de la page",
                }
            },
        },
    },
};

export default messages;