import React from 'react';
import PropTypes from 'prop-types';
import pure from 'recompose/pure';

export const AudioField = ({ elStyle = {}, record, source, title }) => {
    const src = record[source];
    return (
        <div>
            <audio style={{margin:'15px'}} controls autoPlay>
                <source src={src} type="audio/mp3" />
            </audio>
        </div>
    );
};

AudioField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};


const PureAudioField = pure(AudioField);

PureAudioField.defaultProps = {
    addLabel: true,
};

export default PureAudioField;
