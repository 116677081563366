import React, { PropTypes } from 'react';
import numeral from 'numeral';

const DurationField = ({ source, record = {} }) => {
    const d = record[source];
    if (d > 60) {
        const sss = numeral(d).format('00:00');
        const ss = sss.substring(sss.length-5);
        return <span>{ss}</span>;
    } else {
        return <span>{d.toFixed(1)} s</span>;
    }
}

DurationField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default DurationField;