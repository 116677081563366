import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_CHECK, AUTH_ERROR } from 'admin-on-rest';
import { API_BASE_URL } from './config';


export default (type, params) => {
    console.log("Auth", type, params)
    if (type === AUTH_LOGIN) {
        const { username, password } = params;
        const request = new Request(API_BASE_URL+'/api-token-auth', {
            method: 'POST',
            body: JSON.stringify({ username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        })
        return fetch(request)
            .then(response => {
                //console.log("then1");
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                console.log(response);
                //console.log(response.json());
                //console.log(response.json()['token']);
                return response.json();
            })
            .then((response) => {
                const token = response['token'];
                //console.log("then2");
                //console.log("token: ", token);
                localStorage.setItem('token', token);
                //console.log("after login: ", localStorage.getItem('token'));
            });
    }
    if (type === AUTH_LOGOUT) {
        //console.log("before logout: ", localStorage.getItem('token'));
        localStorage.removeItem('token');
        return Promise.resolve();
    }
    if (type === AUTH_CHECK) {
        console.log("check: ", localStorage.getItem('token'));
        return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
    }
    if (type === AUTH_ERROR) {
        const { status } = params;
        if (status === 401) {
            localStorage.removeItem('token');
            return Promise.reject();
        }
        return Promise.resolve();
    }
    return Promise.reject('Unkown method');
}


// 'test', 'df682ecb7ae9'






