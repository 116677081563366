import React from 'react';
import PropTypes from 'prop-types';
import { ShowButton } from 'admin-on-rest/lib/mui';
import CircularProgress from 'material-ui/CircularProgress';


const ShowButtonIfExists = ({ basePath, source, record = {} }) => {
    if (record[source])
        return <ShowButton basePath={basePath} source={source} record={record} />;
    else
        return <CircularProgress />;
}

ShowButtonIfExists.propTypes = {
    basePath: PropTypes.string,
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default ShowButtonIfExists;
