import {
    GET_LIST,
    GET_ONE,
    GET_MANY,
    GET_MANY_REFERENCE,
    CREATE,
    UPDATE,
    DELETE,
    fetchUtils,
} from 'admin-on-rest';

import { API_BASE_URL } from './config';



/**
 * Maps admin-on-rest queries to a json-server powered REST API
 *
 * @see https://github.com/typicode/json-server
 * @example
 * GET_LIST     => GET http://my.api.url/posts?_sort=title&_order=ASC&_start=0&_end=24
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts/123, GET http://my.api.url/posts/456, GET http://my.api.url/posts/789
 * UPDATE       => PUT http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts/123
 * DELETE       => DELETE http://my.api.url/posts/123
 */



export default () => {
    const apiUrl = API_BASE_URL;
    const { fetchJson } = fetchUtils;
    /**
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The REST request params, depending on the type
     * @returns {Object} { url, options } The HTTP request parameters
     */
    const convertRESTRequestToHTTP = (type, resource, params) => {
        //console.log("convertRESTRequestToHTTP 1: ", type, resource);
        let url = '';
        const options = {};
        const { queryParameters } = fetchUtils;

        options.headers = new Headers({ Accept: 'application/json' });
        const token = localStorage.getItem('token');
        options.headers.set('Authorization', `Bearer ${token}`);

        switch (type) {
        case GET_LIST: {
            const { page, perPage } = params.pagination;
            const { field, order } = params.sort;
            const query = {
                ...params.filter,
                _sort: field,
                _order: order,
                _start: (page - 1) * perPage,
                _end: page * perPage,
            };
            url = `${apiUrl}/${resource}?${queryParameters(query)}`;
            break;
        }
        case GET_ONE:
            console.log("GET ONE GET ONE GET ONE GET ONE ");
            url = `${apiUrl}/${resource}/${params.id}`;
            break;
        case GET_MANY_REFERENCE: {
            console.log("GET MANY GET MANY GET MANY GET MANY ");
            const { page, perPage } = params.pagination;
            const { field, order } = params.sort;
            const query = {
                ...params.filter,
                [params.target]: params.id,
                _sort: field,
                _order: order,
                _start: (page - 1) * perPage,
                _end: page * perPage,
            };
            url = `${apiUrl}/${resource}?${queryParameters(query)}`;
            break;
        }
        case UPDATE:
            url = `${apiUrl}/${resource}/${params.id}`;
            options.method = 'PUT';
            options.body = JSON.stringify(params.data);
            break;
        case CREATE:
            url = `${apiUrl}/${resource}`;
            options.method = 'POST';
            options.body = JSON.stringify(params.data);
            break;
        case DELETE:
            url = `${apiUrl}/${resource}/${params.id}`;
            options.method = 'DELETE';
            break;
        default:
            throw new Error(`Unsupported fetch action type ${type}`);
        }

        console.log("Calling API: ", options.method, url);
        return { url, options };
    };

    /**
     * @param {Object} response HTTP response from fetch()
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The REST request params, depending on the type
     * @returns {Object} REST response
     */
    const convertHTTPResponseToREST = (response, type, resource, params) => {
        const { headers, json } = response;
        console.log("convertHTTPResponseToREST headers:", response);
        console.log("convertHTTPResponseToREST json:", json);
        switch (type) {
        case GET_LIST:
        case GET_MANY_REFERENCE:
            if (!headers.has('x-total-count')) {
                throw new Error('The X-Total-Count header is missing in the HTTP Response. The jsonServer REST client expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?');
            }
            return {
                data: json,
                total: parseInt(headers.get('x-total-count').split('/').pop(), 10),
            };
//        case CREATE:
//
//            console.log("CREATE response:", response);
//            console.log("CREATE json:", json);
//            const data = { data: { ...params.data, id: json.id } };
//            console.log("CREATE params.data:", params.data);
//            console.log("CREATE data:", data);
//            return data;
        default:
            return { data: json };
        }
    };

    /**
     * @param {string} type Request type, e.g GET_LIST
     * @param {string} resource Resource name, e.g. "posts"
     * @param {Object} payload Request parameters. Depends on the request type
     * @returns {Promise} the Promise for a REST response
     */
    return (type, resource, params) => {

        if (type === CREATE && resource === 'masks') {
            const formData = new FormData();

            formData.append('name', params.data.name);

            for (var i in params.data.maskfiles) {
                const file = params.data.maskfiles[i];
                formData.append('maskfiles', file.rawFile);
            }

            const headers = new Headers({ Accept: 'application/json' });
            const token = localStorage.getItem('token');
            headers.set('Authorization', `Bearer ${token}`);

            return fetchJson(`${apiUrl}/${resource}`, {
                method: 'POST',
                body: formData,
                headers: headers,
            })
            .then(response => convertHTTPResponseToREST(response, type, resource, params));
        }
        if (type === CREATE && resource === 'musics') {
            const formData = new FormData();
            formData.append('musicfile', params.data.musicfile[0].rawFile);

            const headers = new Headers({ Accept: 'application/json' });
            const token = localStorage.getItem('token');
            headers.set('Authorization', `Bearer ${token}`);

            return fetchJson(`${apiUrl}/${resource}`, {
                method: 'POST',
                body: formData,
                headers: headers,
            })
            .then(response => convertHTTPResponseToREST(response, type, resource, params));
        }

        // json-server doesn't handle WHERE IN requests, so we fallback to calling GET_ONE n times instead
        if (type === GET_MANY) {
            const options = {headers: new Headers({ Accept: 'application/json' })};
            const token = localStorage.getItem('token');
            options.headers.set('Authorization', `Bearer ${token}`);

            return Promise.all(params.ids.map(id => fetchJson(`${apiUrl}/${resource}/${id}`, options)))
                .then(responses => ({ data: responses.map(response => response.json) }));
        }
        const { url, options } = convertRESTRequestToHTTP(type, resource, params);
        return fetchJson(url, options)
            .then(response => convertHTTPResponseToREST(response, type, resource, params));
    };
};
