import TimeAgo from 'react-timeago'
import React from 'react';
import PropTypes from 'prop-types';


const TimeAgoField = ({ source, record = {} }) => {
    const date = record[source];
    return <TimeAgo date={date} />;
}

TimeAgoField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default TimeAgoField;
