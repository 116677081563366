import React from 'react';
import { List, Show, Edit, Create, Datagrid, TextField, ShowButton, DisabledInput, SimpleForm, TextInput, ImageInput, ImageField, SimpleShowLayout, BooleanField, DateField, ReferenceInput, SelectInput, EditButton, ReferenceField } from 'admin-on-rest/lib/mui';
import { required } from 'admin-on-rest';
import MaskField from './fields/MaskField';
import MaskUrlField from './fields/MaskUrlField';
import NameOwnerFilter from './NameOwnerFilter';
import ListField from './fields/ListField';
import { IdNameField, IdNameRenderer } from './fields/IdNameField';



export const MaskList = (props) => (
    <List {...props} perPage={30} sort={{ field: 'date_created', order: 'DESC' }} filters={<NameOwnerFilter />} >
        <Datagrid>
            <TextField source="id"/>
            <DateField source="date_created" />
            <TextField source="name" />
            <BooleanField source="is_animated" sortable={false} />
            <ListField source="owners" sortable={false} />
            <ShowButton />
            <EditButton />
        </Datagrid>
    </List>
);


export const MaskEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
                <DisabledInput source="id" />
                <TextInput source="name" validation={{ required: true }} />
                <MaskField source="url"/>

                <ReferenceInput source="event_id" reference="eventlist" linkType="show" perPage={300} sort={{ field: 'id', order: 'DESC' }} allowEmpty>
                    <SelectInput optionText={IdNameRenderer} />
                </ReferenceInput>
        </SimpleForm>
    </Edit>
);


export const MaskCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect="show">
            <TextInput source="name" validate={required} />
            <ImageInput source="maskfiles" multiple accept="image/*">
                <ImageField source="src" title="title" />
            </ImageInput>
        </SimpleForm>
    </Create>
);


export const MaskShow = (props) => (
    <Show {...props}>

        <SimpleShowLayout>
            <TextField source="name" />
            <DateField source="date_created" />
            <MaskField source="url"/>
            <MaskUrlField source="id" />
            <ReferenceField source="event_id" linkType="show" reference="events" addLabel={true}>
                <TextField source="id"/>
            </ReferenceField>
        </SimpleShowLayout>

    </Show>
);



