import React from 'react';
import { List, Show, Edit, Datagrid, Create, DateField, ImageInput, ImageField, TextField, ShowButton, DisabledInput, NumberInput, SimpleForm, TextInput, SimpleShowLayout } from 'admin-on-rest/lib/mui';
import DurationField from './fields/DurationField';
import NameOwnerFilter from './NameOwnerFilter';
import AudioField from './fields/AudioField';

export const MusicList = (props) => (
    <List {...props} perPage={30} sort={{ field: 'date_created', order: 'DESC' }} filters={<NameOwnerFilter />} >
        <Datagrid>
            <TextField source="id" />
            <DateField source="date_created" />
            <TextField source="name" />
            <DurationField source="length" />
            <DurationField source="time_to_skip" />
            <ShowButton />
        </Datagrid>
    </List>
);


export const MusicEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <DisabledInput label="Id" source="id" />
            <DisabledInput source="date_created" />
            <DisabledInput source="length" />
            <TextInput source="name" />
            <NumberInput source="time_to_skip" />

        </SimpleForm>
    </Edit>
);

export const MusicCreate = (props) => (
    <Create title="Add Music" {...props}>
        <SimpleForm>
            <ImageInput source="musicfile" accept="audio/*">
                <ImageField source="src" title="title" />
            </ImageInput>
        </SimpleForm>
    </Create>
);


export const MusicShow = (props) => (
    <Show hasEdit={false} {...props}>

        <SimpleShowLayout>
            <TextField source="id" />
            <DateField source="date_created" />
            <TextField source="name" />
            <TextField source="length" />
            <TextField source="time_to_skip" />
            <AudioField source="file" />
        </SimpleShowLayout>

    </Show>
);