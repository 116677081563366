import React from 'react';
import { List, Show, SimpleShowLayout, Datagrid, TextField, ShowButton, UrlField, ImageField, DateField } from 'admin-on-rest/lib/mui';
import NameOwnerFilter from './NameOwnerFilter';


export const PageList = (props) => (
    <List {...props} perPage={30} sort={{ field: 'date_created', order: 'DESC' }} filters={<NameOwnerFilter />} >
        <Datagrid>
            <TextField source="id"/>
            <TextField source="name"/>
            <ImageField source="picture" />
            <ShowButton />
        </Datagrid>
    </List>
);


export const PageShow = (props) => (
    <Show {...props} hasEdit={false} hasDelete={true}>

        <SimpleShowLayout>
            <ImageField source="picture" />
            <TextField source="name" />
            <TextField source="page_id" />
            <UrlField source="url" display="Page URL" />
            <TextField source="event_name" />
            <DateField source="event_date" />
            <TextField source="event_id" />
        </SimpleShowLayout>

    </Show>
);
