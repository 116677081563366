import React from 'react';
import { List, Show, Edit, Create, Datagrid, TextField, EditButton, ShowButton, DisabledInput, LongTextInput, ReferenceInput, SelectInput, SimpleForm, TextInput, TabbedForm, FormTab, SimpleShowLayout, DateField, NumberField, ReferenceField, RichTextField, required } from 'admin-on-rest/lib/mui';
import { IdNameRenderer } from './fields/IdNameField';
import GalleryUrlField from './fields/GalleryUrlField';
import UrlField from './fields/UrlField';
import ListField from './fields/ListField';
import NameOwnerFilter from './NameOwnerFilter';
import { BooleanInput } from 'admin-on-rest/lib/mui/input';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form'


export const EventList = (props) => (
    <List {...props} perPage={30} sort={{ field: 'date_created', order: 'DESC' }} filters={<NameOwnerFilter />}>
        <Datagrid>
            <TextField source="id" />
            <DateField source="date_created" />
            <TextField source="name" />
            <ListField source="owners" sortable={false} />
            <NumberField source="snap_count" sortable={false} />
            <ShowButton />
            <EditButton />
        </Datagrid>
    </List>
);


const EventTitleShow = ({ record }) => {
    return <span>View event {record ? `"${record.name}"` : ''}</span>;
};

const EventTitleEdit = ({ record }) => {
    return <span>Edit event {record ? `"${record.name}"` : ''}</span>;
};

export const EventEdit = (props) => {
    return (
    <Edit title={<EventTitleEdit />} {...props}>
        <TabbedForm>
            <FormTab label="Summary">
                <DisabledInput label="Id" source="id" />
                <TextInput source="name" validation={{ required: true }} />

                <ReferenceInput source="mask_id" reference="masklist" perPage={300} sort={{ field: 'id', order: 'DESC' }} allowEmpty>
                    <SelectInput optionText={IdNameRenderer} />
                </ReferenceInput>
            </FormTab>

            <FormTab label="Email">
                <TextInput source="sender"/>
                <TextInput source="subject"/>
                <LongTextInput source="message"/>

                <ReferenceInput source="facebook_page" reference="pagelist" perPage={200} sort={{ field: 'id', order: 'DESC' }} allowEmpty>
                    <SelectInput optionText={IdNameRenderer} />
                </ReferenceInput>
            </FormTab>

            <FormTab label="Hashtag Auto">
                <TextInput source="hashtag"/>
                <BooleanInput label="Sms WhatsApp (option)" source="sms_whatsapp" />
            </FormTab>

            <FormTab label="Gallery">
                <DisabledInput source="name" />
                <TextInput source="subtitle" />
                <TextInput source="url" />
                <TextInput source="password" />
            </FormTab>

        </TabbedForm>

    </Edit>
)};

export const EventCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" validate={required} />
        </SimpleForm>
    </Create>
);


export const EventShow = (props) => (
    <Show title={<EventTitleShow />} hasEdit={false} {...props}>

        <SimpleShowLayout>
            <TextField label="ID" source="id" />
            <TextField source="name" addLabel={true} />

            <ReferenceField source="mask_id" linkType="show" reference="masks" addLabel={true}>
                <TextField source="id"/>
            </ReferenceField>

            <TextField source="sender"/>
            <TextField source="subject"/>
            <RichTextField source="message"/>

            <ReferenceField source="facebook_page" linkType="show" reference="pages" addLabel={true}>
                <TextField source="id"/>
            </ReferenceField>

            {/* <UrlField source="facebook_page_url" display="Facebook Page" /> */}

            <TextField source="hashtag"/>

            <TextField source="subtitle" />
            <GalleryUrlField source="url" />
            <TextField source="password" />

            <UrlField source="admin_stats" display="Statistics" />
            <UrlField source="admin_email_stats" display="Statistics Emails" />
            <UrlField source="admin_selection" display="Manage snap selection" />

        </SimpleShowLayout>

    </Show>
);


