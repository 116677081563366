import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FlatButton from 'material-ui/FlatButton';
import { showNotification as showNotificationAction } from 'admin-on-rest';
import { push as pushAction } from 'react-router-redux';
import { FACEBOOK_URL } from '../config'


class FacebookButton extends Component {   
    render() {
        return (
            <a href={FACEBOOK_URL}>
                <FlatButton label="Connect Facebook" onClick={this.setRedirect} />
            </a>
        );
    }
}

FacebookButton.propTypes = {
    push: PropTypes.func,
    record: PropTypes.object,
    showNotification: PropTypes.func,
};

export default connect(null, {
    showNotification: showNotificationAction,
    push: pushAction,
})(FacebookButton);