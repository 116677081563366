import React from 'react';
import 'intl';
import 'intl/locale-data/jsonp/en';
import { Edit, List, Show, Create, EditButton, Datagrid, ReferenceField, TextField, ReferenceInput, SelectInput, SimpleForm, DateField, DateInput, SimpleShowLayout } from 'admin-on-rest/lib/mui';
import { IdNameField, IdNameRenderer, IdThemeRenderer } from './fields/IdNameField';
import ShowButtonIfExists from './fields/ShowButtonIfExists';
import NameOwnerFilter from './NameOwnerFilter';
import UrlField from './fields/UrlField';


export const ReportList = (props) => (
    <List {...props} perPage={30} sort={{ field: 'date_created', order: 'DESC' }} filters={<NameOwnerFilter />} >
        <Datagrid>
            <TextField source="id" />
            <DateField source="begin_date" />
            <DateField source="end_date" />
            <ReferenceField source="event_id" linkType="show" reference="events" addLabel={true}>
                <IdNameField source="id" />
            </ReferenceField>
            <TextField source="owner" />

            <ShowButtonIfExists source="generated" label="" />
            <EditButton />
        </Datagrid>
    </List>
);

const validateReportCreation = (values) => {
    const errors = {};

    return errors
};


export const ReportEdit = (props) => (
    <Edit title={props.id} {...props}>
        <SimpleForm validate={validateReportCreation}>
            <DateInput source="begin_date" />
            <DateInput source="end_date" />

            <ReferenceInput source="event_id" reference="eventlist" perPage={300} sort={{ field: 'last_used', order: 'DESC' }} allowEmpty>
                <SelectInput optionText={IdNameRenderer} />
            </ReferenceInput>

            <ReferenceInput source="name" reference="ownerlist" perPage={5000} sort={{ field: 'id', order: 'DESC' }} allowEmpty>
                <SelectInput optionText={IdNameRenderer} />
            </ReferenceInput>

            <ReferenceInput source="theme" reference="themelist" perPage={5000} sort={{ field: 'id', order: 'DESC' }} allowEmpty>
                <SelectInput optionText={IdThemeRenderer} />
            </ReferenceInput>

        </SimpleForm>
    </Edit>
);


export const ReportCreate = (props) => (
    <Create {...props}>
        <SimpleForm validate={validateReportCreation}>
            <DateInput source="begin_date" />
            <DateInput source="end_date" />

            <ReferenceInput source="event_id" reference="eventlist" perPage={300} sort={{ field: 'last_used', order: 'DESC' }} allowEmpty>
                <SelectInput optionText={IdNameRenderer} />
            </ReferenceInput>

            <ReferenceInput source="name" reference="ownerlist" perPage={5000} sort={{ field: 'id', order: 'DESC' }} allowEmpty>
                <SelectInput optionText={IdNameRenderer} />
            </ReferenceInput>

            <ReferenceInput source="theme" reference="themelist" perPage={5000} sort={{ field: 'id', order: 'DESC' }} allowEmpty>
                <SelectInput optionText={IdThemeRenderer} />
            </ReferenceInput>

        </SimpleForm>
    </Create>
);


export const ReportShow = (props) => (
    <Show {...props} hasEdit={true} >

        <SimpleShowLayout>
            <TextField source="id" />

            <DateField source="begin_date" />
            <DateField source="end_date" />

            <ReferenceField source="event_id" linkType="show" reference="events" addLabel={true}>
                <IdNameField source="id" />
            </ReferenceField>

            <TextField source="owner" />

            <TextField source="snaps_nb" />

            <UrlField source="url" display="Report Page" />

        </SimpleShowLayout>

    </Show>
);
