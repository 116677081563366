import React, { Component } from 'react';
import { Card, CardTitle } from 'material-ui/Card';
import { GET_ONE } from 'admin-on-rest';
import myRestClient from './jsonServer';
import EventIcon from 'material-ui/svg-icons/action/event';
import SnapIcon from 'material-ui/svg-icons/image/photo';
import BoxIcon from 'material-ui/svg-icons/image/camera-alt';
import FacebookButton from './fields/FacebookButton';

const styles = {
    flex: { marginRight: '-2em', display: 'flex' },
    card: { marginRight: '2em', flex: 1 },
    icon: { float: 'right', width: 64, height: 64, padding: 16, color: '#31708f' },
    facebook: { float: 'left', width: 215, height: 64, padding: 16 },
};


class Dashboard extends Component {
    state = {};

    componentDidMount() {
        myRestClient()(GET_ONE, 'dashboard', {id:0})
            .then(response => {
                console.log(response.data);
                this.setState(response.data);
            });

    }

    render() {
        const {
            total_snaps,
            total_events,
            total_box,
            total_box_online,
        } = this.state;

        return (<div>
                    <div style={styles.flex}>
                    <Card style={styles.card}>
                        <SnapIcon style={styles.icon} />
                        <CardTitle title={total_snaps} subtitle='Snaps' />
                    </Card>

                    <Card style={styles.card}>
                        <EventIcon style={styles.icon} />
                        <CardTitle title={total_events} subtitle='Events' />
                    </Card>

                    <Card style={styles.card}>
                        <BoxIcon style={styles.icon} />
                        <CardTitle title={`${total_box_online}/${total_box}`} subtitle='Boxes online' />
                    </Card>

                    </div>
                    <div style={styles.facebook}>

                    <Card style={styles.card}>
                        <FacebookButton />
                    </Card>
                    </div>
                </div>);
    }
}


// {`Snaps today: ${total_snaps_last_day}`}

export default (Dashboard);