import React from 'react';

import { Admin, Resource, Delete } from 'admin-on-rest';

import { LOCALE } from './config';

// interface translations
import { englishMessages } from 'admin-on-rest';
import frenchMessages from 'aor-language-french';

// domain translations
import domainMessages from './i18n';

import { EventList, EventEdit, EventShow, EventCreate } from './events';
import { BoxList, BoxEdit } from './boxes';
import { MaskList, MaskEdit, MaskShow, MaskCreate } from './masks';
import { MusicList, MusicCreate, MusicEdit, MusicShow } from './musics';
import { VideoList, VideoCreate, VideoEdit, VideoShow } from './videos';
import { ReportList, ReportCreate, ReportEdit, ReportShow } from './report';
import { PageList, PageShow } from './pages';

import authClient from './authClient';
import myRestClient from './jsonServer';

import Dashboard from './dashboard';

import EventIcon from 'material-ui/svg-icons/action/event';
import MaskIcon from 'material-ui/svg-icons/image/photo';
import BoxIcon from 'material-ui/svg-icons/image/camera-alt';
import VideoIcon from 'material-ui/svg-icons/av/movie';
import MusicIcon from 'material-ui/svg-icons/image/music-note';
import ReportIcon from 'material-ui/svg-icons/editor/insert-chart.js';

const messages = {
    fr: { ...frenchMessages, ...domainMessages.fr },
    en: { ...englishMessages, ...domainMessages.en },
};


console.log("Lang="+LOCALE);

const App = () => (
    <Admin dashboard={Dashboard} authClient={authClient} restClient={myRestClient()} title="Ouisnap" locale={LOCALE} messages={messages}>
        <Resource name="boxes" list={BoxList} edit={BoxEdit} icon={BoxIcon} />
        <Resource name="events" list={EventList} show={EventShow} edit={EventEdit} create={EventCreate} icon={EventIcon} />
        <Resource name="eventlist" />
        <Resource name="masks" list={MaskList} show={MaskShow} edit={MaskEdit} create={MaskCreate} icon={MaskIcon} remove={Delete} />
        <Resource name="masklist" />
        <Resource name="musics" list={MusicList} create={MusicCreate} show={MusicShow} edit={MusicEdit} icon={MusicIcon} />
        <Resource name="musiclist" />
        <Resource name="videos" list={VideoList} icon={VideoIcon} edit={VideoEdit} show={VideoShow} create={VideoCreate} />
        <Resource name="report" list={ReportList} edit={ReportEdit} show={ReportShow} create={ReportCreate} icon={ReportIcon} />
        <Resource name="ownerlist" />
        <Resource name="themelist" />
        <Resource name="pages" list={PageList} show={PageShow} remove={Delete} />
        <Resource name="pagelist"  />
    </Admin>
);


export default App;

