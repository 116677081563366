import React from 'react';
import PropTypes from 'prop-types';
import pure from 'recompose/pure';

import { GALLERY_BASE_URL } from '../config';

const GalleryUrlField = ({ source, record = {} }) => {
    const u = record[source];
    if (u) {
        const url = GALLERY_BASE_URL  + '/' + record[source];
        return <span><a href={url} target="_blank">{url}</a></span>;
    } else {
        return <span></span>;
    }
}

GalleryUrlField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

const PureGalleryUrlField = pure(GalleryUrlField);

PureGalleryUrlField.defaultProps = {
    addLabel: true,
};

export default PureGalleryUrlField;
