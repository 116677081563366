import React from 'react';
import { TextInput, Filter } from 'admin-on-rest/lib/mui';


const NameOwnerFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Owner" source="owner" defaultValue="me" />
        <TextInput label="Name" source="name" alwaysOn />
    </Filter>
);


export default NameOwnerFilter;