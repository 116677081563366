import { resolveBrowserLocale } from 'admin-on-rest';

var BASE_URL;

var hostname = window.location.host.split(":")[0];

if (hostname === "localhost" || hostname === "127.0.0.1") {
    BASE_URL = "http://localhost:8000";
} else if (hostname === "10.8.0.42") {
    BASE_URL = "http://10.8.0.42:8000";
} else if (hostname === "my.ouisnap.com" || hostname === 'jacques') {
    BASE_URL = "https://ouisnap.com";
// } else if (hostname === "dev.sosnap.studio") {
//     BASE_URL = "https://dev.sosnap.fr";
// } else if (hostname === "stg.sosnap.studio") {
//     BASE_URL = "https://stg.sosnap.fr";
}


export const LOCALE = resolveBrowserLocale();

export const API_BASE_URL = BASE_URL + '/my';
export const GALLERY_BASE_URL = BASE_URL + '/e';
export const MASK_BASE_URL = BASE_URL + '/mask';
export const FACEBOOK_URL = BASE_URL + '/facebook-redirect-connection';


