import React from 'react';
import PropTypes from 'prop-types';
import pure from 'recompose/pure';
import { MASK_BASE_URL } from '../config';

const MaskUrlField = ({ display, source, record = {} }) => {
    const url = MASK_BASE_URL + '/' + record.shasum;
    return <span><a href={url} target="_blank">Mask public link</a></span>;
}

MaskUrlField.propTypes = {
    label: PropTypes.string,
    display: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

const PureMaskUrlField = pure(MaskUrlField);

PureMaskUrlField.defaultProps = {
    addLabel: true,
    label: "URL",
};

export default PureMaskUrlField;

