import React, { PropTypes } from 'react';

const IdNameField = ({ source, record = {} }) => {

    if (record)
        return (<span>#{record.id} - {record.name}</span>);

    return <span></span>;
}
IdNameField.defaultProps = { label: 'Object' };

IdNameField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export { IdNameField };


export const IdNameRenderer = choice => `#${choice.id} - ${choice.name}`;

export const IdThemeRenderer = choice => `#${choice.id} - ${choice.theme}`;