import React from 'react';
import PropTypes from 'prop-types';
import pure from 'recompose/pure';

const UrlField = ({ display, source, record = {} }) => {
    const url = record[source];
    const d = display ? display : url;
    if (url) {
        return <span><a href={url} target="_blank">{d}</a></span>;
    } else {
        return <span></span>;
    }
}

UrlField.propTypes = {
    label: PropTypes.string,
    display: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

const PureUrlField = pure(UrlField);

PureUrlField.defaultProps = {
    addLabel: true,
};

export default PureUrlField;

