import React from 'react';
import 'intl';
import 'intl/locale-data/jsonp/en';
import { List, Show, Create, Datagrid, ReferenceField, TextField, ReferenceInput, SelectInput, SimpleForm, TextInput, DateField, DateInput, SimpleShowLayout, NumberInput, BooleanInput } from 'admin-on-rest/lib/mui';
import { IdNameField, IdNameRenderer } from './fields/IdNameField';
import ShowButtonIfExists from './fields/ShowButtonIfExists';
import VideoField from './fields/VideoField';
import TimeInput from './fields/TimeInput';
import { Redirect } from 'react-router'
import { LOCALE } from './config';
import NameOwnerFilter from './NameOwnerFilter';
import ListField from './fields/ListField';


export const VideoList = (props) => (
    <List {...props} perPage={30} sort={{ field: 'date_created', order: 'DESC' }} filters={<NameOwnerFilter />} >
        <Datagrid>
            <TextField source="id" />
            <DateField source="date_created" />
            <TextField source="name" />
            <ListField source="owners" sortable={false} />

            <ReferenceField source="event_id" linkType="show" reference="events" sortable={false} >
                <IdNameField source="id" />
            </ReferenceField>

            <TextField source="snap_count" />

            <ShowButtonIfExists source="file" label="" />
        </Datagrid>
    </List>
);

const validateVideoCreation = (values) => {
    const errors = {};
    if (!values.name) { errors.name = ['Required']; }
    if (!values.event_id) { errors.event_id = ['Required']; }

    if (values.loop < 1 || values.loop > 4) { errors.loop = ['Loop number must be between 1 and 4']; }

    if (Math.floor(values.loop) !== Math.ceil(values.loop)) { errors.loop = ['Loop number must be integer']; }

    if (values.snap_date_min && !values.snap_time_min) { errors.snap_time_min = ['Required']; }
    if (values.snap_date_max && !values.snap_time_max) { errors.snap_time_max = ['Required']; }

    return errors
};


export const VideoEdit = (props) => (
    <Redirect to="/videos"/>
);


//import DateTimeInput from './fields/DateTimeInput';

export const VideoCreate = (props) => (
    <Create {...props}>
        <SimpleForm validate={validateVideoCreation}>
            <TextInput source="name" />

            <ReferenceInput source="event_id" reference="eventlist" perPage={300} sort={{ field: 'last_used', order: 'DESC' }} allowEmpty>
                <SelectInput optionText={IdNameRenderer} />
            </ReferenceInput>

            <SelectInput source="snap_selection" defaultValue="bestof" choices={[
                { id: 'bestof', name: 'Best of only' },
                { id: 'all', name: 'All snaps' },
            ]} />

            <NumberInput source="loop" defaultValue={1} />

            <BooleanInput source="randomize" />

            <DateInput source="snap_date_min" options={{maxDate: new Date(), locale: LOCALE}} />
            <TimeInput source="snap_time_min" options={{format: '24hr'}} />

            <DateInput source="snap_date_max" options={{maxDate: new Date(), locale: LOCALE}} />
            <TimeInput source="snap_time_max" options={{format: '24hr'}} />

            <ReferenceInput source="music_id" reference="musiclist" perPage={10000} sort={{ field: 'id', order: 'DESC' }} allowEmpty>
                <SelectInput optionText={IdNameRenderer} />
            </ReferenceInput>

        </SimpleForm>
    </Create>
);


export const VideoShow = (props) => (
    <Show {...props} hasEdit={false} >

        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="name" />

            <ReferenceField source="event_id" linkType="show" reference="events" addLabel={true}>
                <IdNameField source="id" />
            </ReferenceField>

            <ReferenceField source="music_id" allowEmpty={true} linkType="show" reference="musics" addLabel={true}>
                <IdNameField source="id" />
            </ReferenceField>

            <VideoField source="file" />
            <VideoField source="file_square" />

        </SimpleShowLayout>

    </Show>
);
