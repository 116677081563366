import React from 'react';
import { List, Edit,  Datagrid, ReferenceField, TextField, EditButton, DisabledInput, ReferenceInput, SelectInput, SimpleForm } from 'admin-on-rest/lib/mui';
import { IdNameField, IdNameRenderer } from './fields/IdNameField';
import TimeAgoField from './fields/TimeAgoField';
import ListField from './fields/ListField';


export const BoxList = (props) => (
    <List {...props} perPage={30} sort={{ field: 'date_last_login', order: 'DESC' }} >
        <Datagrid>
            <TextField source="id" />
            <ListField source="owners" sortable={false} />
            <ReferenceField source="event_id" linkType="show" reference="events" linkToShow={true} >
                <IdNameField source="name" />
            </ReferenceField>
            <TimeAgoField source="date_last_login" />
            <EditButton/>
        </Datagrid>
    </List>
);


export const BoxEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <DisabledInput source="id" />

            <ReferenceInput source="event_id" reference="eventlist" linkType="show" perPage={300} sort={{ field: 'last_used', order: 'DESC' }} allowEmpty>
                <SelectInput optionText={IdNameRenderer} />
            </ReferenceInput>

        </SimpleForm>
    </Edit>
);
