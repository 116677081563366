import React from 'react';
import PropTypes from 'prop-types';


export const MaskField = ({ elStyle = {}, record, source, title }) => {

    const src = record[source];
    const srcValue = src + '.jpg';
    const is_animated = src.slice(-4) === 'webp';

    const style = {
        backgroundImage: `url(${srcValue})`,
        width: '360px',
        height: '480px',
    };

    const classes = is_animated ? "snap boomerang" : "snap";

    return (
        <div className={classes} style={style} />
    );
};

MaskField.propTypes = {
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default MaskField;
