import React from 'react';
import PropTypes from 'prop-types';
import pure from 'recompose/pure';
import Chip from 'material-ui/Chip';


const ListField = ({ display, source, record = {} }) => {
    const list = record[source];

    //list = array([1,2,3]);
    const elStyle = { margin: 4 };

    return (<div>
                {list.map((i) => <Chip key={i}style={elStyle}>{i}</Chip>)}
            </div>)

}

ListField.propTypes = {
    label: PropTypes.string,
    display: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

const PureListField = pure(ListField);

PureListField.defaultProps = {
    addLabel: true,
};

export default PureListField;

